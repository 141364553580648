.Modal{
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 75%;
    border: 1px solid #ccc;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 7px;
    padding: 1rem;
    left: 15%;
    top: 15%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}

@media (min-width: 600px){
    .Modal{
        width: 600px;
        left: calc(50% - 300px);
    }
}

@media (max-height: 900px){
    .Modal{
        top: 3.72rem;
    }
}

@media only screen and (max-width: 600px) {
    .Modal{
        width: 95%;
        left: 2.5%;
    }
}