/* Auth */
.auth_form__container {
  padding: 1.5rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* All titles */
.auth_form__h1,
.checkin_form__h1,
.user_form__h1 {
  color: #3b3b3b;
  font-family: inherit;
  font-weight: 900;
  text-align: left;
  font-size: 1.45rem;
}

.auth_form__h1 {
  margin-bottom: 1.4rem;
}

.auth_form__info {
  margin-bottom: 1rem;
}
.auth_form__formgroup,
.user__formgroup {
  display: flex;
  flex-direction: column;
}

.auth_form__formgroup{
  margin-bottom: 1.5rem;
}

.auth_form__formgroup label {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  width: 9rem;
  text-align: left;
  margin-bottom: 0.5rem;
  color: #3b3b3b;
}

.auth_form__formgroup input,
.form__btn {
  font: inherit;
  border-radius: 0.3rem;
  width: 100%;
  font-size: 1.15rem;
  padding: 0.8rem;
}

.auth_form__formgroup input {
  border: 1px solid #150958;
  text-align: left;
}

.auth_form__pswlabel {
  bottom: 3.4rem;
}

.auth_pswlink {
  bottom: 3.95rem;
  right: 0;
  color: #150958;
  text-decoration: none;
}

.form__btn {
  background-color: #150958;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form__btn:hover {
  background-color: #150958da;
  border-color: #150958da;
}

.form__error {
  background-color: #ff00002a;
  color: red;
  padding: 0.5rem 4.7rem;
  margin-bottom: 3rem;
}

.form__btn:disabled {
  opacity: 0.3;
  cursor: auto;
}

/* Checkin */

.checkin_form__div {
  width: 100%;
}

.checkin_form__header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  background-color: #150958;
  margin-top: -1.1rem;
  margin-left: -1.1rem;
  margin-right: -1.1rem;
  margin-bottom: 1.4rem;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding: 1.1rem;
  color: white;
}
.checkin_form__h1 {
  color: white;
}
.checkin_form__body {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.checkin_form__group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.4rem 0;
}


.with_icon {
  position: relative;
}
.checkin_form__input__icon{
  font-size: 0.9rem;
  position: absolute;
  right: 0.8rem;
}
.react-time-picker__clear-button{
  position: absolute;
  right: 1.5rem;
  bottom: 0.3rem;
  top: 0.1rem;
}

.checkin_form_timepicker{
  border: 1px solid #150958;
  border-radius: 0.3rem;
  padding: 0.3rem;
  padding-left: 1rem;
  font-size: 1rem;
  width: 12.5rem;
}

.react-time-picker__wrapper{
  border: none !important;
}

.checkin_form__group select{
  min-width: 200px;
}
.checkin_form__group label,
.checkin_form_select{
  font-size: 1.2rem;
  width: 48%;
}
.checkin_form__group select,
.checkin_input {
  border: 1px solid #150958;
}

.btn-primary,
.btn-secondary {
  border: none;
  font-size: 1.2rem;
  padding: 0.5rem 1.5rem;
  border-radius: 0.3rem;
}

.btn-primary {
  background-color: #150958;
  color: white;
}

.btn-secondary {
  border: 1px solid #150958;
  background-color: white;
  color: #150958;
}

.btn-primary:hover,
.btn-primary:active {
  background-color: #150958c3;
  cursor: pointer;
}

.btn-secondary:hover,
.btn-secondary:active {
  background-color: #831a4b78;
  border-color: #831a4b78;
  color: white;
  cursor: pointer;
}

.btn-primary:disabled {
  background-color: #15095872;
  color: #fffefe;
  cursor: default;
}

/*-------------------------*/
/*  Date and time pickers  */
/*-------------------------*/
.checkin_form__datepicker{
  border: 1px solid #150958;
  border-radius: 0.3rem;
  font-size: 1rem;
  padding: 0.3rem;
  width: 200px;
  margin-left: 12rem;
  text-align: left;
  padding-left: 1rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #150958 !important;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  border: 1px solid #150958;
  background-color: #f0f0f0;
  border-radius: 0.3rem;
}

.checkin_date_picker {
  width: 10rem;
  text-align: center;
  margin-left: 11rem;
  padding: 0.5rem;
  font-size: 1rem;
}

.checkin_form_time-picker {
  text-align: center;
}

.react-time-picker__inputGroup__input {
  min-width: 2rem;
  text-align: center;
}


/* Users */
.user_form_container {
  margin: -1.1rem;
  overflow: hidden;
}
.user_form__h1 {
  color: white;
}

.user_form__header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 1rem;
  background-color: #150958;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding: 1.1rem;
  color: white;
}
.user_form__body {
  padding: 1rem;
}

.user__formgroup {
  margin-bottom: 0.75rem;
}

.user__formgroup input {
  font: inherit;
  font-size: 0.9rem;
  border-radius: 0.3rem;
  width: 100%;
  padding: 0.4rem;
  border: 1px solid #150958;
}

.user__formgroup_oneline {
  display: flex;
  justify-content: space-between;
}
.user__formgroup_oneline .user__formgroup {
  width: 100%;
}
.user__formgroup_oneline .first {
  width: 20rem;
  margin-right: 2rem;
}
.user__formgroup_oneline .half {
  width: 50%;
  margin-right: 2rem;
}

.user__formgroup select {
  width: 19.7rem;
  height: 2.1rem;
  padding: 0.5rem;
  border: 1px solid #150958;
}

.user_formgroup__controls,
.checkin_formgroup__controls {
  display: flex;
  justify-content: space-between;
}

input.input-error,
select.input.error {
  border-color: #fc8181;
}

@media (max-width: 700px) {
  .user__formgroup_oneline {
    flex-direction: column;
  }
  .user__formgroup_oneline .first,
  .user__formgroup_oneline .half {
    width: auto;
    margin-right: 0;
  }
}
