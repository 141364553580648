@font-face {
  font-family: "OpenSans";
  src: url("./assets/fonts/openSans-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "OpenSans";
  src: url("./assets/fonts/openSans-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "OpenSans";
  src: url("./assets/fonts/openSans-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "OpenSans";
  src: url("./assets/fonts/openSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "OpenSans";
  src: url("./assets/fonts/openSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "OpenSans",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  font-size: 15px;
  background-color: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
