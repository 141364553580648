.rows-per-page {
  margin-right: 0.5rem;
}

.table__wrapper {
  height: 505px;
  overflow: auto;
  position:relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  background-color: white;
  border-radius: 0.3rem;

}

.table {
  width: 100%;
  padding: 0;
  text-align: left;
  border-collapse: collapse;
  border-radius: 0.3rem;
  overflow: hidden;
}


.table__head{
  background-color: #150958;
  color: white;
}


.table thead th {
  padding: 0.8rem 0.5rem;
}
.table tbody tr{
  height: 0.7rem;

}
.table tbody tr:hover{
  background-color: #f4f4f4;
  cursor: pointer;
}

.table tbody tr.selected{
  background-color: #dfdfdf;
}

.table tbody tr td {
  padding: 0.3rem 0.5rem;
  border-bottom: 1px solid #e6e5e5;
}
.table thead tr th:first-child,
.table tbody tr td:first-child {
  min-width: auto;
}
.table thead tr th:nth-child(2),
.table tbody tr td:nth-child(2),
.table thead tr th:nth-child(3),
.table tbody tr td:nth-child(3),
.table thead tr th:nth-child(4),
.table tbody tr td:nth-child(4) {
  text-align: left;
}

.table thead tr th:last-child,
.table tbody tr td:last-child {
  text-align: right;

}

@media (max-width: 700px) {
  .table thead tr th:first-child,
  .table tbody tr td:first-child {
    min-width: auto;
    max-width: 3.8rem;
  }
}
