.main_nav {
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
}

.main_nav__element, .logoutButton {
  list-style: none;
  font-size: 1rem;
}
.logoutButton:hover{
  cursor: pointer;
}
.main_nav__element a {
  text-decoration: none;
  color: white;
  width: auto;

}

.active,
.main_nav__element a:hover,
.main_nav__element a:active {
  color: rgb(227, 224, 224);
  border-bottom: 0.2rem white solid;
  box-sizing: border-box;
}

@media (max-width: 700px) {
  .main_nav_wrapper, .main_nav {
    display: none;
  }
}
