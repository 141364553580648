.Footer__wrap {
  display: none;
  bottom: 0;
  left: 0;
  height: 5.33rem;
  width: 100%;
  background-color:#150958;
  padding-bottom: 2rem;
}

.Footer{
    display: flex;
    height: 100%;
    width: 100%;
    gap: 1rem;
    color: white;
    align-items: center;
    justify-content: space-around;
}

.Footer__element{
    padding: 1rem;
    font-size: 1.2rem;
    color: white;
}

.Footer__element a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    text-decoration: none;
}

.Footer__element:active{

}

@media (max-width: 700px) {
    .Footer__wrap {
      display: block;
      position: fixed;
    }
}