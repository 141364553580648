.options {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
  }
  .left_options,
  .right_options {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1rem;
  }

  .option {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    gap: 0.3rem;
    align-items: center;
    padding: 0.3rem;
  }
  .option:hover,
  .option:active {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    background-color: #e3e3e3;
    border-radius: 0.3rem;
  }

  .option:disabled {
    cursor: default;
  }

  .option:disabled:hover {
    box-shadow: none;
    background-color: transparent;
  }

  @media (max-width: 700px) {
    .left_options {
      display: none;
    }
  }