.AdminOptions {
  display: flex;
  background-color: white;
  box-sizing: border-box;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  padding: 0.8rem;
  border: 1px solid #e3e3e3;
  border-radius: 0.3rem;
  height: 4rem;
}

.AdminOptions:hover {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  border: none;
}

@media (max-width: 700px) {
  .AdminOptions {
    display: none;
  }
}