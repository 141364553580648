.checkin {
  width: 250px;
  height: 250px;
  border: none;
  font-size: 1.6rem;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border: 3px white solid;
  outline: none;
  animation: signal 3s infinite;
  position: relative;
  background-color: #150958;
}

.ring {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 3px #ffffff solid;
  opacity: 0;
  animation-name: start-ring;
  animation-delay: 3.5s;
  animation-duration: 3.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes start-ring {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  12.5% {
    opacity: 0;
    transform: scale(0.995);
  }
  16.667% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale(1.3);
  }
}

.checkin:active,
.checkout:active {
  transform: scale(0.97);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.errorMessage {
  background-color: #ff00001d;
  border-left: 5px solid red;
  padding: 1rem;
}
