.home {
  color: white;
  width: 100%;
  height: calc(100vh - 57px);
  background-color: #150958;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px white;
}

@media (max-width: 600px) {
  .home {
    padding: 40px;
  }
}

@media (max-width: 700px) {
  .home {
    height: calc(100vh - 137px);
  }
}
