.header {
    background-color: #150958;
    color: white;
    height: 57px;
}

.Logo{
    height:30px;
}

.header__content{
    display: flex;
    width: 100vw;
    height: 57px;
    align-items: center;
    justify-content: space-between;
}

