.flex {
  display: flex;
  align-items: center;
}

.container {
  max-width: 980px;
  margin: 0 auto;
}

.loading {
  font-size: 1.5rem;
  font-weight: 600;
}

select {
  padding: 0.3rem 0.7rem;
  text-align: left;
  font-size: 0.9rem;
  background-color: #f6f6f6;
  border: none;
  border-radius: 0.3rem;
  outline: none;
  cursor: pointer;
}
.select_user {
  border: 1px solid #150958;
}
.select_perPage {
  background-color: white;
}
.shadow {
  box-shadow: 0px 5px 20px rgba(45, 45, 45, 0.4);
}
.margin_bottom_2_rem {
  margin-bottom: 2rem;
}
.red {
  color: red;
}
.align-right {
  text-align: right;
}
.mr-35 {
  margin-right: 3.5rem;
}

@media (max-width: 700px) {
  .hide_on_mobile {
    display: none;
  }
}

@media (max-width: 1024px) {
  .container {
    margin: 0 1.1rem;
  }
}

/**/
.container .body {
  margin-top: 2.5rem;
  margin-bottom: 100px;
}

@media (max-width: 700px) {
  .body {
    height: calc(100vh - 137px);
    margin-bottom: 12.5rem;
  }
}

@media (max-width: 700px) and (max-height: 667px) {
  .body {
    padding-bottom: 40rem;
  }
}
/**/

.Pagination {
  display: flex;
  justify-content: space-between;
  background-color: white;

  padding: 0.8rem;
  border-radius: 0.3rem;
}
.Pagination__total,
.Pagination__buttons {
  display: flex;
  gap: 1rem;
}
.Pagination__buttons:hover {
  cursor: pointer;
}

.Pagination__buttons .disabled {
  display: none;
}
.Pagination:hover {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}
